<template>
  <a-flex align="center" justify="center" style="height: 100px">
    <a-spin size="large" v-if="loading" />
  </a-flex>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpin",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped></style>
