import { createStore } from "vuex";
import newParcel from "./newParcel";
import { loginWithToken, performLogin } from "@/api/auth";
import errorModal from "@/store/errorModal";
import userStore from "@/store/UserStore";

export default createStore({
  modules: {
    newParcel,
    errorModal,
    userStore,
  },
  state: {
    isLogin: false,
  },
  getters: {
    isLogin: (state) => state.isLogin,
  },
  mutations: {
    setLogin(state, status: boolean) {
      state.isLogin = status;
    },
  },
  actions: {
    async login({ commit, dispatch }, { username, password }) {
      try {
        const token = await performLogin(username, password);
        if (token) {
          await loginWithToken(token);
          commit("setLogin", true);
          await dispatch("fetchCurrentUser");
        }
      } catch (error) {
        dispatch("logout");
        console.error("Ошибка при авторизации:", error);
        throw error;
      }
    },
    logout({ commit }) {
      document.cookie =
        "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      commit("setLogin", false);
    },

    async checkTokenAndLogin({ commit, dispatch }) {
      const token = getCookie("accessToken");
      if (token) {
        try {
          await loginWithToken(token);
          commit("setLogin", true);
          await dispatch("fetchCurrentUser");
        } catch (error) {
          console.error("Ошибка при авторизации по токену:", error);
          dispatch("logout");
          throw error;
        }
      }

      function getCookie(name: string) {
        if (typeof document !== "undefined") {
          const value = `; ${document.cookie}`;
          const parts = value.split(`; ${name}=`);
          if (parts.length === 2) return parts.pop()?.split(";").shift();
        }
        return null;
      }
    },
  },
});
