<template>
  <a-config-provider
    :theme="{
      token: {
        colorPrimary: '#121212',
        fontFamily: 'Raleway',
        borderRadius: 12,
        colorError: '#fc0004',
      },
    }"
  >
    <div class="page-wrapper" v-if="!loading">
      <router-view />
      <ModalError :error="getError" @onOk="clearError" />
    </div>
    <a-flex justify="center" align="center" style="height: 90vh"  v-if="loading">
      <LoadingSpin :loading="loading" />
    </a-flex>
  </a-config-provider>
</template>

<script type="ts">
import {mapActions, mapGetters, mapMutations} from "vuex";
import ModalError from "@/components/ModalError.vue";
import LoadingSpin from "@/components/LoadingSpin.vue";

export default {
  name: 'App',
  components: { LoadingSpin, ModalError },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapActions(['checkTokenAndLogin', 'fetchCurrentUser']),
    ...mapMutations(['clearError'])
  },
  computed: {
    ...mapGetters(['getError'])
  },
  async created () {
    this.loading = true;
    try {
      await this.checkTokenAndLogin();
    } catch (e) {
      console.error(e);
      this.$message.error('Failed to authenticate. Please try again.');
    } finally {
      this.loading = false;
    }
  }
};
</script>
