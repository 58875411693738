import { Configuration } from "@/api/clients/backend";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

interface ErrorResponse {
  message: string;
}

export const defaultAxiosSettings = {
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
    },
}

export class CustomAxiosError extends Error {
  response?: AxiosResponse<ErrorResponse>;
  request?: AxiosRequestConfig;

  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, CustomAxiosError.prototype);
  }
}

// Функция для добавления общих интерцепторов
export function addErrorInterceptors(instance: AxiosInstance): AxiosInstance {
  instance.interceptors.response.use(
    (response) => response,
    (error: AxiosError<ErrorResponse>) => {
      const customError = new CustomAxiosError(error.message);

      if (error.response) {
        customError.message = `Error: ${error.response.status} - ${error.response.data?.message}`;
        customError.response = error.response;
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
      } else if (error.request) {
        customError.message =
          "The server did not respond. Please try again later.";
        customError.request = error.request;
      }

      return Promise.reject(customError);
    }
  );

  return instance;
}

// To Do сделать еще один config для авторизации с использованием public url
