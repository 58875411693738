import axios from "axios";
import { Configuration } from "@/api/clients/backend";
import { addErrorInterceptors, defaultAxiosSettings } from "@/api/axiosConfigs/defaultAxiosConfig";

export const config = new Configuration({
  basePath: process.env.VUE_APP_PROTECTED_API_URL,
  baseOptions: defaultAxiosSettings,
});

export const axiosInstance = addErrorInterceptors(
  axios.create({
    baseURL: config.basePath,
    ...defaultAxiosSettings,
  })
);