/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Tariff
 */
export interface Tariff {
    /**
     * 
     * @type {number}
     * @memberof Tariff
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'createdAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Tariff
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tariff
     */
    'key'?: string;
}
/**
 * 
 * @export
 * @interface TariffRate
 */
export interface TariffRate {
    /**
     * 
     * @type {number}
     * @memberof TariffRate
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TariffRate
     */
    'fromWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof TariffRate
     */
    'toWeight'?: number;
    /**
     * 
     * @type {string}
     * @memberof TariffRate
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TariffRate
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TariffRate
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof TariffRate
     */
    'tariff_id'?: number;
}
/**
 * 
 * @export
 * @interface UpdateRateRequest
 */
export interface UpdateRateRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateRateRequest
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateRateRequest
     */
    'id'?: number;
}

/**
 * TariffControllerApi - axios parameter creator
 * @export
 */
export const TariffControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tariffs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatesForTariff: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRatesForTariff', 'id', id)
            const localVarPath = `/tariffs/{id}/rates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatesForTariff1: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getRatesForTariff1', 'key', key)
            const localVarPath = `/tariffs/by-key/{key}/rates`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} rateId 
         * @param {UpdateRateRequest} updateRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariff: async (id: number, rateId: number, updateRateRequest: UpdateRateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTariff', 'id', id)
            // verify required parameter 'rateId' is not null or undefined
            assertParamExists('updateTariff', 'rateId', rateId)
            // verify required parameter 'updateRateRequest' is not null or undefined
            assertParamExists('updateTariff', 'updateRateRequest', updateRateRequest)
            const localVarPath = `/tariffs/{id}/rates/{rate_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"rate_id"}}`, encodeURIComponent(String(rateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<UpdateRateRequest>} updateRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariffRates: async (id: number, updateRateRequest: Array<UpdateRateRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTariffRates', 'id', id)
            // verify required parameter 'updateRateRequest' is not null or undefined
            assertParamExists('updateTariffRates', 'updateRateRequest', updateRateRequest)
            const localVarPath = `/tariffs/{id}/rates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TariffControllerApi - functional programming interface
 * @export
 */
export const TariffControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TariffControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tariff>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffControllerApi.getAll']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRatesForTariff(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TariffRate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRatesForTariff(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffControllerApi.getRatesForTariff']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRatesForTariff1(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TariffRate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRatesForTariff1(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffControllerApi.getRatesForTariff1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} rateId 
         * @param {UpdateRateRequest} updateRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTariff(id: number, rateId: number, updateRateRequest: UpdateRateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TariffRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTariff(id, rateId, updateRateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffControllerApi.updateTariff']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<UpdateRateRequest>} updateRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTariffRates(id: number, updateRateRequest: Array<UpdateRateRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTariffRates(id, updateRateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TariffControllerApi.updateTariffRates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TariffControllerApi - factory interface
 * @export
 */
export const TariffControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TariffControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: RawAxiosRequestConfig): AxiosPromise<Array<Tariff>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatesForTariff(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<TariffRate>> {
            return localVarFp.getRatesForTariff(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRatesForTariff1(key: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<TariffRate>> {
            return localVarFp.getRatesForTariff1(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} rateId 
         * @param {UpdateRateRequest} updateRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariff(id: number, rateId: number, updateRateRequest: UpdateRateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TariffRate> {
            return localVarFp.updateTariff(id, rateId, updateRateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<UpdateRateRequest>} updateRateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTariffRates(id: number, updateRateRequest: Array<UpdateRateRequest>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateTariffRates(id, updateRateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TariffControllerApi - object-oriented interface
 * @export
 * @class TariffControllerApi
 * @extends {BaseAPI}
 */
export class TariffControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffControllerApi
     */
    public getAll(options?: RawAxiosRequestConfig) {
        return TariffControllerApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffControllerApi
     */
    public getRatesForTariff(id: number, options?: RawAxiosRequestConfig) {
        return TariffControllerApiFp(this.configuration).getRatesForTariff(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffControllerApi
     */
    public getRatesForTariff1(key: string, options?: RawAxiosRequestConfig) {
        return TariffControllerApiFp(this.configuration).getRatesForTariff1(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} rateId 
     * @param {UpdateRateRequest} updateRateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffControllerApi
     */
    public updateTariff(id: number, rateId: number, updateRateRequest: UpdateRateRequest, options?: RawAxiosRequestConfig) {
        return TariffControllerApiFp(this.configuration).updateTariff(id, rateId, updateRateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<UpdateRateRequest>} updateRateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TariffControllerApi
     */
    public updateTariffRates(id: number, updateRateRequest: Array<UpdateRateRequest>, options?: RawAxiosRequestConfig) {
        return TariffControllerApiFp(this.configuration).updateTariffRates(id, updateRateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



