import axios from "axios";
import { Configuration } from "@/api/clients/calculator";
import { addErrorInterceptors, defaultAxiosSettings } from "@/api/axiosConfigs/defaultAxiosConfig";

export const calculatorConfig = new Configuration({
  basePath: process.env.VUE_APP_CALCULATOR_API_URL,
  baseOptions: defaultAxiosSettings,
});

export const tariffsAxiosInstance = addErrorInterceptors(
  axios.create({
    baseURL: calculatorConfig.basePath,
    ...defaultAxiosSettings,
  })
);