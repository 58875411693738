import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_flex = _resolveComponent("a-flex")!

  return (_openBlock(), _createBlock(_component_a_flex, {
    align: "center",
    justify: "center",
    style: {"height":"100px"}
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_spin, {
            key: 0,
            size: "large"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}