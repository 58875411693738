import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/register-incoming-parcel/:id",
    name: "RegisterIncomingParcel",
    component: () => import("../views/RegisterIncomingParcel.vue"),
  },
  {
    path: "/register-incoming-parcel/successful/:id",
    name: "SuccessfulRegisterIncomingParcel",
    component: () => import("../views/SuccessfulRegisterIncomingParcel.vue"),
  },
  {
    path: "/new-parcel",
    name: "new-parcel",
    component: () => import("../views/NewParcel.vue"),
  },
  {
    path: "/initial-receipt",
    name: "InitialReceiptView",
    component: () => import("../views/InitialReceipt.vue"),
  },
  {
    path: "/outgoing-parcels",
    name: "outgoing-parcels",
    component: () => import("@/views/OutgoingParcels.vue"),
  },
  {
    path: "/register-outgoing-parcel/:id",
    name: "SuccessfulRegisteredOutgoingParcel",
    component: () => import("../views/SuccessfulRegisteredOutgoingParcel.vue"),
  },
  {
    path: "/registry",
    name: "registry",
    component: () => import("@/views/RegistryView.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/SettingsView.vue"),
  },
  {
    path: "/problem-parcel-final:id",
    name: "ProblemParcelFinalView",
    component: () => import("../views/ProblemParcelFinalView.vue"),
  },
  {
    path: "/address-storage",
    name: "AddressStorageView",
    component: () => import("../views/AddressStorageView.vue"),
  },
  {
    path: "/users",
    name: "UsersView",
    component: () => import("../views/UsersView.vue"),
  },
  {
    path: "/customers",
    name: "CustomersView",
    component: () => import("../views/CustomersView.vue"),
  },
  {
    path: "/roles",
    name: "RolesView",
    component: () => import("../views/RolesView.vue"),
  },
  {
    path: "/create-role",
    name: "CreateRoleView",
    component: () => import("../views/CreateRoleView.vue"),
  },
  {
    path: "/registry-export",
    name: "RegistryExportView",
    component: () => import("../views/RegistryExportView.vue"),
  },
  {
    path: "/relabel",
    name: "RelabelView",
    component: () => import("../views/RelabelView.vue"),
  },
  {
    path: "/consignments",
    name: "ConsignmentsView",
    component: () => import("../views/ConsignmentsView.vue"),
  },
  {
    path: "/consolidation/:id",
    name: "ConsolidationView",
    component: () => import("../views/ConsolidationView.vue"),
  },
  {
    path: "/containers",
    name: "ContainersView",
    component: () => import("../views/ContainersView.vue"),
  },
  {
    path: "/container/:id",
    name: "ConsolidationContainerView",
    component: () => import("../views/ConsolidationContainerView.vue"),
  },
  {
    path: "/pallets",
    name: "PalletsView",
    component: () => import("../views/PalletsView.vue"),
  },
  {
    path: "/logs",
    name: "LogsView",
    component: () => import("../views/LogsView.vue"),
  },
  {
    path: "/country-dict",
    name: "CountryDictView",
    component: () => import("../views/CountryDictView.vue"),
  },
  {
    path: "/sandbox/edit-parcel",
    name: "EditParcelView",
    component: () => import("../views/sandbox/EditParcelView.vue"),
  },
  {
    path: "/edit-tariffs",
    name: "EditTariffsView",
    component: () => import("../views/sandbox/EditTariffsView.vue"),
  },
  {
    path: "/result-search/",
    name: "SearchResultView",
    component: () => import("../views/SearchResultView.vue"),
    props: (route) => ({ query: route.query }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/login" && !store.getters.isLogin) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
